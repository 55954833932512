import { configureStore,getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";
import newsReducer from "../features/newsSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    news: newsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,   // Refresh token axiosundan sonra veridği bir uyarı veya hatayı giderdi bi bakılmalı 
  }),
});
export default store;
