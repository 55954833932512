// Library
import React, { useState, useEffect } from "react";
import WOW from "wowjs";
import axios from "axios";

// components
import Main from "../components/Main";
import Features from "../components/Features";
import About from "../components/About";
import Pricing from "../components/Pricing";
import Faq from "../components/Faq";
import Testimonials from "../components/Testimonials";
import Team from "../components/Team";
import Contact from "../components/Contact";
import BacktoTop from "../components/BacktoTop";
import About2 from "../components/About2";

const Home = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);

  return (
    <div>
      <Main />
      <Features />
      {/* <About /> */}
      <About2/>

      {/* <Pricing /> */}
      <Testimonials />
      <Faq />
      
      <Team />
      <Contact />
      <BacktoTop/>
    </div>
  );
}

export default Home
