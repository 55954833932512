import React from "react";
import authorImage1 from "../assets/images/testimonials/author-01.png";
import authorImage2 from "../assets/images/testimonials/author-02.png";
import authorImage3 from "../assets/images/testimonials/author-03.png";
import lineiconsImage from "../assets/images/brands/lineicons.svg";
import uideckImage from "../assets/images/brands/uideck.svg";
import tailgridsImage from "../assets/images/brands/tailgrids.svg";
import graygridsImage from "../assets/images/brands/graygrids.svg";
import kuntImage from "../assets/images/brands/logo_siyah.png";
import gumusImage from "../assets/images/brands/logogumus.png";


import ayrouiImage from "../assets/images/brands/ayroui.svg";

const Testimonials = () => {

  return (
    <section id="testimonials" className="pt-40 mb-36 md:pt-[120px]">
      <div className="container px-4">
        <div className="flex flex-wrap">
          <div className="mx-4 w-full">
            <div className="mx-auto mb-[60px] max-w-[820px] text-center lg:mb-20">
              
              <h2 className="mb-4 text-3xl font-bold text-dark-900 sm:text-4xl md:text-[42px]">
                Yazılımımızın  Bıraktığı Etki
              </h2>
              <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                Müşterilerimizin deneyimleri ve geri bildirimleri, yazılımımızın
                 nasıl bir etki yarattığını en iyi şekilde
                ortaya koymaktadır. İşte kullanıcılarımızın yazılımımız
                hakkındaki görüşleri
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="ud-single-testimonial wow fadeInUp mb-12 bg-secondary-100 p-8 shadow-testimonial"
              data-wow-delay=".1s
              "
            >
              <div className="ud-testimonial-ratings mb-3 flex items-center">
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
              </div>
              <div className="ud-testimonial-content mb-6">
                <p className="text-base tracking-wide text-body-color">
                  “Yazılımınız, iş süreçlerimizi büyük ölçüde kolaylaştırdı.
                  Dosya yönetiminden masraf takibine kadar her şeyi tek bir
                  platformda birleştirmesi harika. Verimlilik artışı gözle
                  görülür bir şekilde hissediliyor.
                </p>
              </div>
              <div className="ud-testimonial-info flex items-center">
                <div className="ud-testimonial-image mr-5 h-[50px] w-[50px] overflow-hidden rounded-full">
                  <img src={authorImage1} alt="author" />
                </div>
                <div className="ud-testimonial-meta">
                  <h4 className="text-sm font-semibold"> A. K.</h4>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="ud-single-testimonial wow fadeInUp mb-12 bg-secondary-100 p-8 shadow-testimonial"
              data-wow-delay=".15s
              "
            >
              <div className="ud-testimonial-ratings mb-3 flex items-center">
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
              </div>
              <div className="ud-testimonial-content mb-6">
                <p className="text-base tracking-wide text-body-color">
                  “Görev takvimi özelliği sayesinde artık önemli tarihleri
                  kaçırma endişesi yaşamıyorum. İşlerin durumunu anlık olarak
                  takip edebiliyorum ve avukat arkadaşlarımın sorumluluklarını
                  daha iyi yönetebiliyorum. İşbirliğini kolaylaştıran bu
                  yazılım, büromuzun verimliliğini artırdı.
                </p>
              </div>
              <div className="ud-testimonial-info flex items-center">
                <div className="ud-testimonial-image mr-5 h-[50px] w-[50px] overflow-hidden rounded-full">
                  <img src={authorImage2} alt="author" />
                </div>
                <div className="ud-testimonial-meta">
                  <h4 className="text-sm font-semibold">Avukat B. Y.</h4>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/3">
            <div
              className="ud-single-testimonial wow fadeInUp mb-12 bg-secondary-100 p-8 shadow-testimonial"
              data-wow-delay=".2s
              "
            >
              <div className="ud-testimonial-ratings mb-3 flex items-center">
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
                <span className="mr-1 text-warning-800">
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    className="fill-current"
                  >
                    <path d="M9.09815 0.360596L11.1054 6.06493H17.601L12.3459 9.5904L14.3532 15.2947L9.09815 11.7693L3.84309 15.2947L5.85035 9.5904L0.595291 6.06493H7.0909L9.09815 0.360596Z" />
                  </svg>
                </span>
              </div>
              <div className="ud-testimonial-content mb-6">
                <p className="text-base tracking-wide text-body-color">
                  “Hukuki işlerde güvenlik ve veri gizliliği çok önemlidir.
                  Sizin yazılımınızın KVKK gerekliliklerine uygun on-premise
                  server kurulumu yapması, verilerimizin güvende olduğunu bilmek
                  beni rahatlattı. Hassas verilerimizi tam kontrol altında
                  tutabiliyoruz.
                </p>
              </div>
              <div className="ud-testimonial-info flex items-center">
                <div className="ud-testimonial-image mr-5 h-[50px] w-[50px] overflow-hidden rounded-full">
                  <img src={authorImage3} alt="author" />
                </div>
                <div className="ud-testimonial-meta">
                  <h4 className="text-sm font-semibold">Avukat C. M.</h4>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default Testimonials;
