import React, { useEffect ,useState} from "react";
import { Outlet, Link } from "react-router-dom";
//css
import "../assets/styles/tailwind.css";
import "../assets/styles/animate.css";

// components
import Footer from "../components/Footer";
import Navbar from "../components/Navbar/Navbar";
import useDarkMode from '../Hook/useDarkMode';



const Layout= () => {
  const [isDarkMode, toggleDarkMode] = useDarkMode();
  return (
    <div className={isDarkMode ? 'dark-mode' : 'light-mode'}>
      <Navbar toggleDarkMode={toggleDarkMode} />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
