import React, { useEffect, useState } from 'react'
import dataleft from "../assets/json/acordiondataleft.json"
import dataright from "../assets/json/acordiondataright.json"



const Faq = () => {

 const [acordionDataLeft, setAcordionDataLeft] = useState([])
 const [acordionDataRight, setAcordionDataRight] = useState([])

useEffect(() => {
  setAcordionDataLeft(dataleft)
  setAcordionDataRight(dataright)
}, [])


  const toggleAccordion = (e) => {
    e.currentTarget.firstElementChild.firstElementChild.classList.toggle("rotate-180");
    e.currentTarget.nextElementSibling.classList.toggle("hidden");
    
  };
  return (
    <section
      className="relative z-20 overflow-hidden bg-gradient-to-tr from-dark-1000 via-dark-1000 to-primary-900 pt-20 pb-12 lg:pt-[120px] lg:pb-[90px]"
    >
      <div className="container">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[620px] text-center lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-dark-700">
                S.S.S
              </span>
              <h2
                className="mb-4 text-3xl font-bold text-secondary-200 sm:text-4xl md:text-[42px]"
              >
                Size Özel Cevaplarımız Burada!
              </h2>

            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 lg:w-1/2">
            {acordionDataLeft.map((item,index) =>(
              <div
              className="single-faq wow fadeInUp mb-8 w-full rounded-lg border border-secondary-200 bg-secondary-100 p-5 sm:p-8"
              data-wow-delay=".1s"
              key={index}
              
            >
              <button className="faq-btn flex w-full items-center text-left" onClick={(e)=>toggleAccordion(e)}>
                <div
                  className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-dark-1000 bg-opacity-5 text-dark-700"
                >
                  <svg
                    width="17"
                    height="10"
                    viewBox="0 0 17 10"
                    className="icon fill-current"
                  >
                    <path
                      d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
                      fill="#3056D3"
                      stroke="#3056D3"
                    />
                  </svg>
                </div>
                <div className="w-full">
                  <h4 className="text-base font-semibold text-black-900 sm:text-lg">
                    {item.title}
                  </h4>
                </div>
              </button>
              <div className='faq-content hidden pl-[62px]'>
                <p className="py-3  text-base leading-relaxed text-body-color">
                  {item.desc}
                </p>
              </div>
            </div>
            ))}
            
            
          </div>
          <div className="w-full px-4 lg:w-1/2">
          {acordionDataRight.map((item,index) =>(
            <div
            className="single-faq wow fadeInUp mb-8 w-full rounded-lg border border-secondary-200 bg-secondary-100 p-5 sm:p-8"
            data-wow-delay=".1s
            "
            key={index}
          >
            <button className="faq-btn flex w-full items-center text-left" onClick={toggleAccordion}>
              <div
                className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-dark-1000 bg-opacity-5 text-dark-700"
              >
                <svg
                  width="17"
                  height="10"
                  viewBox="0 0 17 10"
                  className="icon fill-current"
                >
                  <path
                    d="M7.28687 8.43257L7.28679 8.43265L7.29496 8.43985C7.62576 8.73124 8.02464 8.86001 8.41472 8.86001C8.83092 8.86001 9.22376 8.69083 9.53447 8.41713L9.53454 8.41721L9.54184 8.41052L15.7631 2.70784L15.7691 2.70231L15.7749 2.69659C16.0981 2.38028 16.1985 1.80579 15.7981 1.41393C15.4803 1.1028 14.9167 1.00854 14.5249 1.38489L8.41472 7.00806L2.29995 1.38063L2.29151 1.37286L2.28271 1.36548C1.93092 1.07036 1.38469 1.06804 1.03129 1.41393L1.01755 1.42738L1.00488 1.44184C0.69687 1.79355 0.695778 2.34549 1.0545 2.69659L1.05999 2.70196L1.06565 2.70717L7.28687 8.43257Z"
                    fill="#3056D3"
                    stroke="#3056D3"
                  />
                </svg>
              </div>
              <div className="w-full">
                <h4 className="text-base font-semibold text-black-900 sm:text-lg">
                  {item.title}
                </h4>
              </div>
            </button>
            <div className='faq-content hidden pl-[62px]'>
              <p className="py-3 text-base leading-relaxed text-body-color">
              {item.desc}
              </p>
            </div>
          </div>
          ))}
            
            
          </div>
        </div>
      </div>

    
      {/* <span className='absolute bottom-0 faq-bg'></span> */}
    </section>
  )
}

export default Faq