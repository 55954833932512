import {
  fetchFail,
  fetchStart,
  loginSuccess,
  logoutSuccess,
  registerSuccess,
  refreshSuccess,
} from "../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { json, useNavigate } from "react-router-dom";
import useAxios from "../Hook/useAxios";
import { useEffect } from "react";
const BASE_URL = "http://127.0.0.1:8000/";

const useAuthCall = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser, accessToken } = useSelector((state) => state.auth);
  const { axios } = useAxios();
  useEffect(() => {
    if (!accessToken) {
      const fetchToken = async () => {
        try {
          const response = await axios.post("token/refresh/", {
            withCredentials: true,
          })
          console.log(response.data.access);
          dispatch(refreshSuccess(response));
        } catch (error) {
          console.log(error);
          dispatch(fetchFail());
        }
      };
      fetchToken();
    }
  }, [accessToken, dispatch]);

  const login = async (userInfo) => {
    dispatch(fetchStart());
    try {
      const { data } = await axios.post(`${BASE_URL}login/`, userInfo, {
        withCredentials: true,
      });
      console.log(data);
      dispatch(loginSuccess(data));
      navigate("/");
    } catch (error) {
      console.log(error);
      dispatch(fetchFail());
    }
  };

  const logout = async () => {
    dispatch(fetchStart());
    try {
      await axios.post(`${BASE_URL}logout/`, {
        withCredentials: true,
      });
      dispatch(logoutSuccess());
      navigate("/");
    } catch (error) {
      console.log(error);
      dispatch(fetchFail());
    }
  };

  const register = async (userInfo) => {
    dispatch(fetchStart());
    try {
      const { data } = await axios.post(`${BASE_URL}account/`, userInfo, {
        withCredentials: true,
      });
      console.log(data);
      dispatch(registerSuccess(data));
      navigate("/");
    } catch (err) {
      dispatch(fetchFail());
    }
  };

  return { login, logout, register };
};

export default useAuthCall;
