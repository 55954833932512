import { useEffect, useState } from 'react';

const useDarkMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const isDarkModeEnabled = localStorage.getItem('darkMode') === 'true';
    setIsDarkMode(isDarkModeEnabled);
  }, []);

  const toggleDarkMode = () => {
    const updatedIsDarkMode = !isDarkMode;
    setIsDarkMode(updatedIsDarkMode);
    localStorage.setItem('darkMode', updatedIsDarkMode);
  };

  return [isDarkMode, toggleDarkMode];
};

export default useDarkMode;
