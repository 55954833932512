import React, { useEffect, useState } from "react";
import "./BacktoTop.css";

const BacktoTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="backToTop ">
      <a
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
        className=" backToTop bottom-8 right-8 left-auto z-[999]  h-10 w-10 items-center justify-center rounded-md bg-dark-600 text-secondary-100 shadow-md transition duration-300 ease-in-out hover:bg-dark-900"
      >
        <span className=" backToTop mt-[17px] ml-[13px] h-3 w-3  rotate-45 border-t-2 border-l-2 border-secondary-100"></span>
      </a>
    </div>
  );
};

export default BacktoTop;
