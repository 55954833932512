import axios from 'axios';

const useAxios = () => {
  axios.defaults.baseURL = 'http://127.0.0.1:8000/';
  // Enable sending cookies with requests
  axios.defaults.withCredentials = true;

  // axios.interceptors.request.use((config) => {
  //   if (accessToken) {
  //     config.headers.Authorization = `Bearer ${accessToken}`;
  //   }

  //   return config;
  // });

  return { axios };
};

export default useAxios;
