import React from "react";
import TextField from "@mui/material/TextField";
import { Form } from "formik";
import * as Yup from "yup";

export const registerSchema = Yup.object().shape({
    username: Yup.string()
      .max(10, "username must have less than 10 chars")
      .required(),
    email: Yup.string().email().required(),
    password: Yup.string()
      .required(),
      // .min(8, "Password must have min 8 chars")
      // .max(16, "Password must have max 16 chars")
      // .matches(/\d+/, "Password must have a number")
      // .matches(/[a-z]+/, "Password must have a lowercase")
      // .matches(/[A-Z]+/, "Password must have an uppercase")
      // .matches(/[!,?{}><%&$#£+-.]+/, " Password must have a special char"),
    password2: Yup.string()
      .required()
      // .min(8, "Password must have min 8 chars")
      // .max(16, "Password must have max 16 chars")
      // .matches(/\d+/, "Password must have a number")
      // .matches(/[a-z]+/, "Password must have a lowercase")
      // .matches(/[A-Z]+/, "Password must have an uppercase")
      // .matches(/[!,?{}><%&$#£+-.]+/, " Password must have a special char"),
  });

const RegisterForm = ({ values, handleChange, errors, touched, handleBlur }) => {
  return (
    <Form>
    <div className="mb-6">
      <TextField
      label="Username"
      name="username"
      id="username"
        type="text"
        placeholder="Username"
        value={values.username}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.username && errors.username}
        error={touched.username && Boolean(errors.username)}
        className="bordder-[#E9EDF4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none transition focus:border-primary focus-visible:shadow-none"
      />
    </div>
    <div className="mb-6">
      <TextField
      label="Email"
      name="email"
      id="email"
        type="email"
        placeholder="Email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.email && errors.email}
        error={touched.email && Boolean(errors.email)}
        className="border-[#c4c4c4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none transition focus:border-primary focus-visible:shadow-none"
      />
    </div>
    <div className="mb-6">
      <TextField
        label="Password"
        name="password"
        id="password"
        type="password"
        placeholder="Password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.password && errors.password}
        error={touched.password && Boolean(errors.password)}
        className="border-[#c4c4c4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none transition focus:border-primary focus-visible:shadow-none"
      />
    </div>
    <div className="mb-6">
      <TextField
        label="Password2"
        name="password2"
        id="password2"
        type="password"
        placeholder="Password2"
        value={values.password2}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched.password2 && errors.password2}
        error={touched.password2 && Boolean(errors.password2)}
        className="border-[#c4c4c4] w-full rounded-md border bg-[#FCFDFE] py-3 px-5 text-base text-body-color placeholder-[#ACB6BE] outline-none transition focus:border-primary focus-visible:shadow-none"
      />
    </div>
    <div className="mb-10 ">
      <button
       type="submit"
       
       className="text-secondary-100 w-full  hover:text-white border border-primary-1000 hover:bg-primary-200 focus:ring-4 focus:outline-none focus:ring-primary-200 font-medium rounded-lg py-2.5  text-center  dark:border-primary-200 dark:text-primary-200 dark:hover:text-white dark:hover:bg-primary-200 dark:focus:ring-primary-200 text-base "
     >
       Submit
      </button>

    </div>
  </Form>
  )
  
};

export default RegisterForm;
