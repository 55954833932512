import React from "react";

const Regula = () => {
  return (
    <>
      <div className="relative overflow-hidden bg-primary-1000  pt-[100px] md:pt-[110px] lg:pt-[120px]">
        <div className="container">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 z-[1]"></div>
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden  ">
        <div className="container">
          <div className="-mx-4 flex flex-wrap items-center">
            <div className="w-full px-4 z-[1]"></div>
          </div>
        </div>
        <div className=" mx-96 mt-12 text-[32px]">
          Regula Document Reader SDK Web API
        </div>
        <div className=" mx-96 mt-4 text-[18px]">
          Fast and accurate data extraction from identity documents
        </div>
        <div className=" mx-96  text-[18px]">
          On-premise and cloud integration.
        </div>
      </div>
      <div className="relative overflow-hidden w-full mt-12 bg-primary-400">
        <div className="container  ">
          <div className="grid grid-cols-3  gap-4">
            <div className="mt-4">
              <div>Use your own image</div>
              <button className="btn bg-danger-700 px-4 py-1 mt-2 hover:bg-danger-300 w-48">
                Take a photo{" "}
              </button>
              <button className="btn bg-danger-700 px-6 py-1 mt-2 ml-4 hover:bg-danger-300 w-48">
                Upload{" "}
              </button>
              <div className="mt-4">
                <input
                  type="url"
                  name=""
                  id=""
                  placeholder="Use image URL"
                  className="w-[360px] py-1"
                />
                <button className="btn bg-danger-700  py-1 mt-2 px-[9px] hover:bg-danger-300 ">
                  Go
                </button>
              </div>
            </div>
            <div className="col-span-2 mt-4">
              <div>Choose sample images</div>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <img src="https://api.regulaforensics.com/document-reader/samples/thumbs/AUS_P_thumb.jpg" className="rounded-lg"/>
                </div>
                <div>
                  <img src="https://api.regulaforensics.com/document-reader/samples/thumbs/NOR_P_thumb.jpg" className="rounded-lg"/>
                </div>
                <div>
                  <img src="https://api.regulaforensics.com/document-reader/samples/thumbs/AUS_P_thumb.jpg" className="rounded-lg"/>
                </div>
              </div>
            </div>
            <div className="...">
              <div>Scenario</div>
              <div className="mt-4">
                <select name="" id="" className="w-[340px] py-1">
                  <option value="">Locate</option>
                  <option value="">FullProcess</option>
                  <option value="">Mrz</option>
                  <option value="">Ocr</option>
                  <option value="">Locate</option>
                  <option value="">Locate</option>
                  <option value="">Locate</option>
                  <option value="">Locate</option>
                </select>
              </div>
              <div className="mt-4">Processing side</div>
              <div className="mt-4">
                <input
                  type="radio"
                  name="Processing side"
                  id=""
                  className="mr-2"
                />
                <label htmlFor="age1 " className="mr-5">
                  Web Service
                </label>

                <input
                  type="radio"
                  name="Processing side"
                  id=""
                  className="mr-2"
                />
                <label htmlFor="age1">Web Component</label>
              </div>
            </div>
            <div className="col-span-2 ...">
            <div className="grid grid-cols-3 gap-4">
                <div>
                  <img src="https://api.regulaforensics.com/document-reader/samples/thumbs/AUS_P_thumb.jpg" className="rounded-lg"/>
                </div>
                <div>
                  <img src="https://api.regulaforensics.com/document-reader/samples/thumbs/NOR_P_thumb.jpg" className="rounded-lg"/>
                </div>
                <div>
                  <img src="https://api.regulaforensics.com/document-reader/samples/thumbs/AUS_P_thumb.jpg" className="rounded-lg"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Regula;
