import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",

  initialState: {
    currentUser: null,
    email: null,
    loading: false,
    error: false,
    isAdmin: false,
    accessToken:false
  },
  reducers: {
    fetchStart: (state) => {
      state.loading = true;
      state.error = false;
    },
    loginSuccess: (state, { payload }) => {
      console.log(payload)
      state.loading = false;
      state.currentUser = payload?.user?.username;
      state.email = payload?.user?.email;
      state.isAdmin = payload?.user?.is_superuser;
      state.accessToken = true;
      state.error = false;
    },
    logoutSuccess: (state) => {
      state.loading = false;
      state.currentUser = null;
      state.email = null;
      state.isAdmin = false;
      state.accessToken = false;
      state.error = false;

    },
    registerSuccess: (state, { payload }) => {
      console.log(payload)
      state.loading = false;
      state.currentUser = payload?.username;
      state.email = payload?.email;
      state.isAdmin = payload?.is_superuser;
      state.accessToken = true;
      state.error = false;
    },
    fetchFail: (state) => {
      state.loading = false;
      state.error = true;
    },
    refreshSuccess: (state, { payload }) => {
      state.loading = false;
      state.currentUser = payload?.data?.user?.username;
      state.email = payload?.data?.user?.email;
      state.isAdmin = payload?.data?.user?.is_superuser;
      state.accessToken = true;
      state.error = false;
    },
  },
});

export const {
  fetchStart,
  loginSuccess,
  logoutSuccess,
  registerSuccess,
  refreshSuccess,
  fetchFail,
  
} = authSlice.actions;
export default authSlice.reducer;
