import React from "react";
import Slider from "react-slick";
import aboutImage from "../assets/images/about/about-image.svg";
import dashboardImage from "../assets/images/dashboard.png";
import vultusHome from "../assets/images/vultus14.png";
import Optiread from "../assets/images/optiread.png";

const About2 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.5, // Show 1 slide initially
    slidesToScroll: 0.5, // Scroll 1 slide at a time
    initialSlide: 1, // Start from the first slide
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.5, // Show 1 slide initially
          slidesToScroll: 0.5, // Scroll 1 slide at a time
          initialSlide: 1, // Start from the first slide
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <section
      id="about"
      className="bg-gradient-to-tr from-dark-1000 via-dark-1000 to-primary-900 z-[1] pt-4 pb-4   relative"
    >
      <div className="container">
        <div>
          {/* <Slider {...settings}> */}
          <div className="px-4">
            <div className="p-6  rounded-lg bg-opacity-80 h-[400px] mb-12">
              <div className="text-center">
                <h4 className="mt-3 text-xl font-bold text-primary-400  ">
                  Yapay Zeka Tabanlı Belge Tanıma
                </h4>
              </div>

              <div className="flex  ">
                <div className="w-2/3 my-auto ml-12">
                  <p className="mb-5 text-base leading-relaxed text-white">
                    Yapay zeka ile ehliyet, kimlik, fiş ve faturaları
                    fotoğraflardan tanıyıp metinleri otomatik olarak ilgili
                    alanlara aktarın.
                  </p>
                  <p className="mb-5 text-base leading-relaxed text-white">
                    Manuel veri girişi ile zaman kaybetmeden ve hata riskini
                    minimize ederek çalışın.
                  </p>
                </div>
                <div className="my-auto">
                  <img
                    src={Optiread}
                    alt="About Image"
                    className=" w-[400px] rounded-lg "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-4">
            <div className="p-6  rounded-lg shadow-lg bg-opacity-80 h-[400px] ">
              <div className="text-center">
                <h4 className="mt-3 text-xl font-bold text-primary-400 ">
                  İş Yönetimi Yazılımı
                </h4>
              </div>

              <div className="flex  ">
                <div className="my-auto ">
                  <img
                    src={vultusHome}
                    alt="About Image"
                    className="h-auto w-[400px] rounded-lg "
                  />
                </div>
                <div className="w-2/3 my-auto ml-12">
                  <p className="mb-5 text-base leading-relaxed text-white">
                    Profesyonel ofislerin iş süreçlerini optimize etmek ve
                    verimliliklerini artırmak amacıyla tasarlanmış özel
                    yazılımımız, dosya yönetimi, masraf takibi, iş takibi ve
                    çalışanların görev takvimini tek bir platformda bir araya
                    getirerek işlerinizi daha etkin bir şekilde yönetmenizi
                    sağlar.
                  </p>
                  <p className="mb-5 text-base leading-relaxed text-white">
                    Dijital dosya saklama, harcama takibi ve görev yönetimi gibi
                    özelliklerle iş süreçlerinizi kolayca yönetin.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </Slider> */}
        </div>
      </div>
    </section>
  );
};

export default About2;
