import React from "react";
import teamImage1 from "../assets/images/team/team-01.png";
import teamImage2 from "../assets/images/team/team-02.png";
import teamImage3 from "../assets/images/team/team-03.png";
import teamImage4 from "../assets/images/team/team-04.png";
import Slider from "react-slick";
const Team = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 8, // Show 1 slide initially
    slidesToScroll: 1, // Scroll 1 slide at a time
    autoplay: true, // Auto play the slides
    autoplaySpeed: 1500, // Delay between slides in milliseconds (e.g., 2000ms = 2s)
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7, // Show 1 slide initially
          slidesToScroll: 1, // Scroll 1 slide at a time

          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <section id="team" className=" pt-[20px] pb-[20px] mx-20">
      <div className="flex flex-wrap">
        <div className="mx-4 w-full">
          <div className="wow fadeInUp" data-wow-delay=".2s">
            <div className="ud-title mb-8">
              <h6 className="relative inline-flex items-center text-xs font-normal text-body-color mx-20">
                Kullandığımız Teknolojiler
                <span className="ml-4 inline-block h-[1px] w-8 bg-secondary-300"></span>
              </h6>
            </div>
            {/* <div className="ud-brands-logo flex flex-wrap items-center"> */}
            <Slider {...settings} className="mx-auto ">
              <div className="  mt-2 ">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/47/React.svg/1200px-React.svg.png"
                    alt="react"
                    className="grayscale duration-300 hover:filter-none w-14"
                  />
                </a>
              </div>
              <div className="  mb-5 max-w-[110px]">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://download.logo.wine/logo/Amazon_Web_Services/Amazon_Web_Services-Logo.wine.png"
                    alt="aws"
                    className="grayscale duration-300 hover:filter-none w-24"
                  />
                </a>
              </div>
              <div className="  mt-3 max-w-[110px]">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://www.djangoproject.com/m/img/logos/django-logo-positive.png"
                    alt="django"
                    className="grayscale duration-300 hover:filter-none w-24"
                  />
                </a>
              </div>
              <div className="  mb-5 max-w-[140px]">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://d1.awsstatic.com/acs/characters/Logos/Docker-Logo_Horizontel_279x131.b8a5c41e56b77706656d61080f6a0217a3ba356d.png"
                    alt="docker"
                    className="grayscale duration-300 hover:filter-none w-28"
                  />
                </a>
              </div>
              <div className="  mt-3 max-w-[140px]">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Elasticsearch_logo.svg/2560px-Elasticsearch_logo.svg.png"
                    alt="elastic"
                    className="grayscale duration-300 hover:filter-none w-28"
                  />
                </a>
              </div>
              <div className="  mb-5 ">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png"
                    alt="git"
                    className="grayscale duration-300 hover:filter-none w-12"
                  />
                </a>
              </div>
              <div className="  mb-5 max-w-[110px]">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://miro.medium.com/v2/resize:fit:1200/0*kPvgOBwIwfbT2K0V.png"
                    alt="google cloud"
                    className="grayscale duration-300 hover:filter-none w-28"
                  />
                </a>
              </div>
              <div className="  mb-5 max-w-[110px]">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://i0.wp.com/ieee.ku.edu.tr/wp-content/uploads/2016/03/js-logo.png?fit=500%2C500&ssl=1"
                    alt="js"
                    className="grayscale duration-300 hover:filter-none w-14"
                  />
                </a>
              </div>
              <div className="  mt-3 max-w-[140px]">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Node.js_logo_2015.svg/2560px-Node.js_logo_2015.svg.png"
                    alt="node"
                    className="grayscale duration-300 hover:filter-none w-28"
                  />
                </a>
              </div>
              <div className="  mt-3 max-w-[140px]">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://gorkemarik.com/wp-content/uploads/2023/07/NGINX-logo-rgb-large.png"
                    alt="nginx"
                    className="grayscale duration-300 hover:filter-none w-28"
                  />
                </a>
              </div>

              <div className="  mt-1 ">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://www.drupal.org/files/project-images/nextjs-icon-dark-background.png"
                    alt="nextjs"
                    className="grayscale duration-300 hover:filter-none w-12"
                  />
                </a>
              </div>
              <div className="  mt-3 max-w-[110px]">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/MongoDB_Logo.svg/1280px-MongoDB_Logo.svg.png"
                    alt="mongo"
                    className="grayscale duration-300 hover:filter-none w-28"
                  />
                </a>
              </div>
              <div className="  mb-5 max-w-[110px]">
                <a href="" target="_blank" rel="nofollow noopner">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/TensorFlow_logo.svg/1200px-TensorFlow_logo.svg.png"
                    alt="tensorflow"
                    className="grayscale duration-300 hover:filter-none w-24"
                  />
                </a>
              </div>

              {/* </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
