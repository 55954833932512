import { Provider } from "react-redux";
import store from "./app/store";
import AppRouter from "./router/AppRouter";

// Language
import { I18nextProvider } from 'react-i18next';
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
// Import your language-specific translation files
import enTranslation from "./locales/en.json";
import trTranslation from "./locales/tr.json";
import { GoogleOAuthProvider } from '@react-oauth/google';
function App() {
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: enTranslation,
      },
      tr: {
        translation: trTranslation,
      },
    },
    lng: "tr",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <GoogleOAuthProvider clientId="609112696278-kijvu826u1jnftf0isvkm96cpt5a9o5c.apps.googleusercontent.com">
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </I18nextProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
