import React from "react";
import { Form } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { TextField } from "@mui/material";
export const loginSchema = Yup.object().shape({
  username: Yup.string()
    .max(10, "username must have less than 10 chars")
    .required(),
  password: Yup.string()
    .required("Please enter a password")
    // .max(16, "Password must have max 16 chars")
    // .matches(/\d+/, "Please must have a number"),
  //   .matches(/[a-z]+/, "Please must have a lovercase")
  //   .matches(/[A-Z]+/, "Please must have a uppercase")
  //   .matches(/[!,?{}<>%&$#+-.*/^]+/, "Please must have a special charc"),
});
const LoginForm = ({ values, handleChange, errors, touched, handleBlur }) => {
  const { loading } = useSelector((state) => state.auth);

  return (
    <Form>
      <div className="mb-6">
        <TextField
          label="Username"
          name="username"
          id="username"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.username && Boolean(errors.username)}
          helperText={touched.username && errors.username}
          placeholder="Username"
          value={values.username}
          className="border-[#E9EDF4] w-full rounded-md border bg-secondary-200  py-3 px-5 text-base text-body-color placeholder-secondary-1000  outline-none transition focus:border-primary-700 focus-visible:shadow-none"
        />
      </div>
      <div className="mb-6">
        <TextField
          label="Password"
          name="password"
          id="password"
          type="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
          placeholder="Password"
          className="border-[#E9EDF4] w-full rounded-md border bg-secondary-200 py-3 px-5 text-base text-body-color placeholder-secondary-1000  outline-none transition focus:border-primary-700 focus-visible:shadow-none"
        />
      </div>
      <div className="mb-10 bg-danger-700 text-secondary-100 rounded-md transition duration-300 ease-in-out hover:shadow-md over:border-secondary-100 hover:bg-secondary-100 hover:text-dark-900">
        <button
          type="submit"
          loading={loading}
          className="border-danger-700 w-full cursor-pointer rounded-md border py-3 px-5 text-base "
        >
          Submit
        </button>
      </div>
    </Form>
  );
};

export default LoginForm;
