// Library
import React, { useEffect, useState } from "react";

import WOW from "wowjs";

import { Formik } from "formik";

// components
//import Login from "../components/Signin";
import vultusLogoDark from "../assets/images/Vultus_12.png";

import loginBg2 from "../assets/images/login2.svg";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useSelector } from "react-redux";
import useAuthCall from "../hooks/useAuthCall";
import LoginForm, { loginSchema } from "../components/LoginForm";
import { useGoogleLogin } from "@react-oauth/google";
import useAxios from "../Hook/useAxios";

const Signin = () => {
  const { login } = useAuthCall();
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  const { axios } = useAxios();
  const navigate = useNavigate();
  const [openTab, setOpenTab] = useState(1);
  const google_login = async (credentialResponse) => {
    try {
      const { data } = await axios.post(`google/`, {
        data: credentialResponse,
        withCredentials: true,
        headers: {
          "X-Access-Key": `28ab62db-46db-42df-8e09-e09484548d91`,
          "X-Secret-Key": `e827fbbd-7907-48c7-ad57-6155aa03e516`,
        },
      });
      console.log(data);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };
  const goog_login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.log(credentialResponse);
      google_login(credentialResponse)},
  });
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2  max-lg:bg-secondary-600 bg-white ">
        <div className="relative overflow-hidden h-screen  md:col-span-1  max-lg:hidden      bg-dark-1000   pt-[40px] pb-[20px]">
          <span className="absolute top-20 -left-24 z-[0] opacity-60 ">
            <img src={loginBg2} alt="logo" />
          </span>
        </div>
        <div className="max-lg:col-span-2 max-lg:pt-12 ">
          <div class="flex items-center justify-center px-4 py-10 bg-white sm:px-6 lg:px-8 sm:py-16 lg:py-24 ">
            <div class="xl:w-full xl:max-w-sm 2xl:max-w-md xl:mx-auto mt-36">
              <ul className="flex space-x-2 justify-center ">
                <li>
                  <a
                    href="#"
                    onClick={() => setOpenTab(1)}
                    className={` ${
                      openTab === 1 ? "border-b border-primary-600 text-primary-600 font-bold" : ""
                    } inline-block px-4 py-2 text-dark-600 bg-white  `}
                  >
                    Giriş Yap
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => setOpenTab(2)}
                    className={` ${
                      openTab === 2 ? "border-b border-primary-600 text-primary-600 font-bold" : ""
                    } inline-block px-4 py-2 text-dark-600 bg-white`}
                  >
                    Kayıt Ol
                  </a>
                </li>
              </ul>

              <div className="p-3 mt-6">
                <div className={openTab === 1 ? "block" : "hidden"}>
                  <div
                    class="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
                    id="tabs-home"
                    role="tabpanel"
                    aria-labelledby="tabs-home-tab"
                    data-te-tab-active
                  >
                    <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl">
                      Hemen giriş yap!
                    </h2>

                    <form action="#" method="POST" class="mt-8">
                      <div class="space-y-5">
                        <div>
                          <label
                            for=""
                            class="text-base font-medium text-gray-900"
                          >
                            {" "}
                            Kullanıcı Adı{" "}
                          </label>
                          <div class="mt-2.5">
                            <input
                              type="email"
                              name=""
                              id=""
                              placeholder="Enter email to get started"
                              class="block w-full p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            />
                          </div>
                        </div>

                        <div>
                          <div class="flex items-center justify-between">
                            <label
                              for=""
                              class="text-base font-medium text-gray-900"
                            >
                              {" "}
                              Şifre{" "}
                            </label>

                            <a
                              href="#"
                              title=""
                              class="text-sm font-medium text-blue-600 hover:underline hover:text-blue-700 focus:text-blue-700"
                            >
                              {" "}
                              Forgot password?{" "}
                            </a>
                          </div>
                          <div class="mt-2.5">
                            <input
                              type="password"
                              name=""
                              id=""
                              placeholder="Enter your password"
                              class="block w-full p-4 text-black placeholder-gray-500 transition-all duration-200 border border-gray-200 rounded-md bg-gray-50 focus:outline-none focus:border-blue-600 focus:bg-white caret-blue-600"
                            />
                          </div>
                        </div>

                        <div className="">
                          <button
                            type="submit"
                            className="inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all bg-primary-700 duration-300   rounded-md focus:outline-none hover:bg-primary-500 hover:text-white focus:bg-dark-1000 hover:border-dark-1000"
                          >
                            Giriş Yap
                          </button>
                        </div>
                      </div>
                    </form>

                    <div class="space-y-3 mt-3">
                      <button
                        onClick={() => goog_login()}
                        type="button"
                        className="relative inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-gray-700 transition-all duration-300  border-2 border-secondary-500 rounded-md bg-secondary-500  focus:bg-gray-100 hover:bg-secondary-300 hover:border-secondary-300 hover:text-black focus:text-black focus:outline-none"
                      >
                        <div class="absolute  left-0 p-4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 186.69 190.5"
                            xmlnsV="https://vecta.io/nano"
                          >
                            <g transform="translate(1184.583 765.171)">
                              <path
                                clipPath="none"
                                mask="none"
                                d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483"
                                fill="#4285f4"
                              />
                              <path
                                clipPath="none"
                                mask="none"
                                d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226"
                                fill="#34a853"
                              />
                              <path
                                clipPath="none"
                                mask="none"
                                d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098"
                                fill="#fbbc05"
                              />
                              <path
                                d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226"
                                fill="#ea4335"
                                clipPath="none"
                                mask="none"
                              />
                            </g>
                          </svg>
                        </div>
                        Google ile giriş yap.
                      </button>
                    </div>
                  </div>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"}>
                  React JS with Tailwind CSS Tab 2 Content show
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <span className="absolute top-5 left-5 z-[0]  ">
        <Link
          to="/"
          className="inline-block rounded-lg   py-2 px-8 text-center text-base font-medium text-secondary-100 transition duration-300 ease-in-out hover:border-secondary-700 hover:bg-secondary-700 hover:text-dark-900 max-lg:text-secondary-100  max-lg:hover:border-dark-1000 max-lg:hover:bg-secondary-100 max-lg:bg-dark-1000 max-lg:border-dark-1000"
        >
          <span>
            <FaArrowLeft className="inline-block" /> Back To Homepage{" "}
          </span>
        </Link>
      </span>
      {/* <span className="absolute top-64 left-10 z-[0] opacity-90">
          <img src={loginBg} alt="logo" />
          </span> */}
      {/* <span className="absolute top-40 left-0 z-[0] opacity-80">
          <img src={loginBg1} alt="logo" />
          </span>  */}
    </div>
  );
};

export default Signin;

//  <section className="h-screen py-14 lg:py-20  max-lg:bg-secondary-600  bg-secondary-200">
// <div className="container">
//   <h1 className="text-center text-3xl font-mono	font-bold">
//     Kolay bir şekilde kayıt ol !!!
//   </h1>
//   <div className="-mx-4 flex flex-wrap mt-8">
//     <div className="w-full px-4">
//       <div
//         className="wow fadeInUp   relative mx-auto max-w-[525px] overflow-hidden rounded-lg bg-dark-1000 py-16 px-8 text-center sm:px-12 md:px-[60px]"
//         data-wow-delay=".15s"
//       >
//         <div className="mb-10 text-center">
//           <a
//             href="#"
//             className="mx-auto inline-block max-w-[160px]"
//           >
//             <img src={vultusLogoDark} alt="logo" />
//           </a>
//         </div>

//         <Formik
//           initialValues={{ username: "", password: "" }}
//           validationSchema={loginSchema}
//           onSubmit={(values, actions) => {
//             login(values);
//             actions.resetForm();
//             actions.setSubmitting(false);
//           }}
//           component={(props) => (
//             <LoginForm {...props} />
//           )}
//         ></Formik>
//         <p className="mb-6 text-base text-secondary-300">
//           Connect With
//         </p>
//         <ul className="-mx-2 mb-12 flex justify-between">
//           <li className="w-full px-2">
//             <a
//               href="#"
//               className="flex h-11 items-center justify-center rounded-md bg-primary-700 transition hover:bg-opacity-90"
//             >
//               <svg
//                 width="10"
//                 height="20"
//                 viewBox="0 0 10 20"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M9.29878 8H7.74898H7.19548V7.35484V5.35484V4.70968H7.74898H8.91133C9.21575 4.70968 9.46483 4.45161 9.46483 4.06452V0.645161C9.46483 0.290323 9.24343 0 8.91133 0H6.89106C4.70474 0 3.18262 1.80645 3.18262 4.48387V7.29032V7.93548H2.62912H0.747223C0.359774 7.93548 0 8.29032 0 8.80645V11.129C0 11.5806 0.304424 12 0.747223 12H2.57377H3.12727V12.6452V19.129C3.12727 19.5806 3.43169 20 3.87449 20H6.47593C6.64198 20 6.78036 19.9032 6.89106 19.7742C7.00176 19.6452 7.08478 19.4194 7.08478 19.2258V12.6774V12.0323H7.66596H8.91133C9.2711 12.0323 9.54785 11.7742 9.6032 11.3871V11.3548V11.3226L9.99065 9.09677C10.0183 8.87097 9.99065 8.6129 9.8246 8.35484C9.76925 8.19355 9.52018 8.03226 9.29878 8Z"
//                   fill="white"
//                 />
//               </svg>
//             </a>
//           </li>
//           <li className="w-full px-2">
//             <a
//               href="#"
//               className="flex h-11 items-center justify-center rounded-md bg-[#1C9CEA] transition hover:bg-opacity-90"
//             >
//               <svg
//                 width="22"
//                 height="16"
//                 viewBox="0 0 22 16"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M19.5516 2.75538L20.9 1.25245C21.2903 0.845401 21.3968 0.53229 21.4323 0.375734C20.3677 0.939335 19.3742 1.1272 18.7355 1.1272H18.4871L18.3452 1.00196C17.4935 0.344423 16.429 0 15.2935 0C12.8097 0 10.8581 1.81605 10.8581 3.91389C10.8581 4.03914 10.8581 4.22701 10.8935 4.35225L11 4.97847L10.2548 4.94716C5.7129 4.82192 1.9871 1.37769 1.38387 0.782779C0.390323 2.34834 0.958064 3.85127 1.56129 4.79061L2.76774 6.54403L0.851613 5.6047C0.887097 6.91977 1.45484 7.95303 2.55484 8.7045L3.5129 9.33072L2.55484 9.67515C3.15806 11.272 4.50645 11.9296 5.5 12.18L6.8129 12.4932L5.57097 13.2446C3.58387 14.4971 1.1 14.4031 0 14.3092C2.23548 15.6869 4.89677 16 6.74194 16C8.12581 16 9.15484 15.8748 9.40322 15.7808C19.3387 13.7143 19.8 5.8865 19.8 4.32094V4.10176L20.0129 3.97652C21.2194 2.97456 21.7161 2.44227 22 2.12916C21.8935 2.16047 21.7516 2.22309 21.6097 2.2544L19.5516 2.75538Z"
//                   fill="white"
//                 />
//               </svg>
//             </a>
//           </li>
//           <li className="w-full px-2">
//             <a
//               href="#"
//               className="flex h-11 items-center justify-center rounded-md bg-[#D64937] transition hover:bg-opacity-90"
//             >
//               <svg
//                 width="18"
//                 height="18"
//                 viewBox="0 0 18 18"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M17.8477 8.17132H9.29628V10.643H15.4342C15.1065 14.0743 12.2461 15.5574 9.47506 15.5574C5.95916 15.5574 2.8306 12.8821 2.8306 9.01461C2.8306 5.29251 5.81018 2.47185 9.47506 2.47185C12.2759 2.47185 13.9742 4.24567 13.9742 4.24567L15.7024 2.47185C15.7024 2.47185 13.3783 0.000145544 9.35587 0.000145544C4.05223 -0.0289334 0 4.30383 0 8.98553C0 13.5218 3.81386 18 9.44526 18C14.4212 18 17.9967 14.7141 17.9967 9.79974C18.0264 8.78198 17.8477 8.17132 17.8477 8.17132Z"
//                   fill="white"
//                 />
//               </svg>
//             </a>
//           </li>
//           <li className="w-full px-2">
//           <GoogleLogin
//                 onSuccess={credentialResponse => {
//                   console.log(credentialResponse);
//                   google_login(credentialResponse);
//                 }}
//                 onError={() => {
//                   console.log('Login Failed');
//                 }}
//               />;
//                 </li>
//         </ul>
//         <a
//           href="#"
//           className="mb-2 inline-block text-base text-dark-900 hover:text-primary-700"
//         >
//           Forget Password?
//         </a>
//         <p className="text-base text-dark-900">
//           Not a member yet?
//           <Link
//             to={"/signup"}
//             className="text-primary-700 ml-2 hover:underline"
//           >
//             Sign Up
//           </Link>
//         </p>

//         <div>
//           <span className="absolute top-1 right-1">
//             <svg
//               width="40"
//               height="40"
//               viewBox="0 0 40 40"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <circle
//                 cx="1.39737"
//                 cy="38.6026"
//                 r="1.39737"
//                 transform="rotate(-90 1.39737 38.6026)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="1.39737"
//                 cy="1.99122"
//                 r="1.39737"
//                 transform="rotate(-90 1.39737 1.99122)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="13.6943"
//                 cy="38.6026"
//                 r="1.39737"
//                 transform="rotate(-90 13.6943 38.6026)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="13.6943"
//                 cy="1.99122"
//                 r="1.39737"
//                 transform="rotate(-90 13.6943 1.99122)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="25.9911"
//                 cy="38.6026"
//                 r="1.39737"
//                 transform="rotate(-90 25.9911 38.6026)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="25.9911"
//                 cy="1.99122"
//                 r="1.39737"
//                 transform="rotate(-90 25.9911 1.99122)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="38.288"
//                 cy="38.6026"
//                 r="1.39737"
//                 transform="rotate(-90 38.288 38.6026)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="38.288"
//                 cy="1.99122"
//                 r="1.39737"
//                 transform="rotate(-90 38.288 1.99122)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="1.39737"
//                 cy="26.3057"
//                 r="1.39737"
//                 transform="rotate(-90 1.39737 26.3057)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="13.6943"
//                 cy="26.3057"
//                 r="1.39737"
//                 transform="rotate(-90 13.6943 26.3057)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="25.9911"
//                 cy="26.3057"
//                 r="1.39737"
//                 transform="rotate(-90 25.9911 26.3057)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="38.288"
//                 cy="26.3057"
//                 r="1.39737"
//                 transform="rotate(-90 38.288 26.3057)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="1.39737"
//                 cy="14.0086"
//                 r="1.39737"
//                 transform="rotate(-90 1.39737 14.0086)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="13.6943"
//                 cy="14.0086"
//                 r="1.39737"
//                 transform="rotate(-90 13.6943 14.0086)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="25.9911"
//                 cy="14.0086"
//                 r="1.39737"
//                 transform="rotate(-90 25.9911 14.0086)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="38.288"
//                 cy="14.0086"
//                 r="1.39737"
//                 transform="rotate(-90 38.288 14.0086)"
//                 fill="#e5e7eb"
//               />
//             </svg>
//           </span>
//           <span className="absolute left-1 bottom-1">
//             <svg
//               width="29"
//               height="40"
//               viewBox="0 0 29 40"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <circle
//                 cx="2.288"
//                 cy="25.9912"
//                 r="1.39737"
//                 transform="rotate(-90 2.288 25.9912)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="14.5849"
//                 cy="25.9911"
//                 r="1.39737"
//                 transform="rotate(-90 14.5849 25.9911)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="26.7216"
//                 cy="25.9911"
//                 r="1.39737"
//                 transform="rotate(-90 26.7216 25.9911)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="2.288"
//                 cy="13.6944"
//                 r="1.39737"
//                 transform="rotate(-90 2.288 13.6944)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="14.5849"
//                 cy="13.6943"
//                 r="1.39737"
//                 transform="rotate(-90 14.5849 13.6943)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="26.7216"
//                 cy="13.6943"
//                 r="1.39737"
//                 transform="rotate(-90 26.7216 13.6943)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="2.288"
//                 cy="38.0087"
//                 r="1.39737"
//                 transform="rotate(-90 2.288 38.0087)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="2.288"
//                 cy="1.39739"
//                 r="1.39737"
//                 transform="rotate(-90 2.288 1.39739)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="14.5849"
//                 cy="38.0089"
//                 r="1.39737"
//                 transform="rotate(-90 14.5849 38.0089)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="26.7216"
//                 cy="38.0089"
//                 r="1.39737"
//                 transform="rotate(-90 26.7216 38.0089)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="14.5849"
//                 cy="1.39761"
//                 r="1.39737"
//                 transform="rotate(-90 14.5849 1.39761)"
//                 fill="#e5e7eb"
//               />
//               <circle
//                 cx="26.7216"
//                 cy="1.39761"
//                 r="1.39737"
//                 transform="rotate(-90 26.7216 1.39761)"
//                 fill="#e5e7eb"
//               />
//             </svg>
//           </span>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </section>
