import React from "react";
import vultusHome from "../assets/images/hero/home.png";
import vultusHome1 from "../assets/images/vultus-dashboard.png";

import homeBrand from "../assets/images/hero/brand.svg";
import circle1 from "../assets/images/footer/shape-1.svg";
import circle3 from "../assets/images/footer/shape-3.svg";
import MainBg from "../assets/images/main.svg";
import CodeBlog from "./CodeBlog";
import Main1 from "../assets/images/main/carbon_2.png";
import Main2 from "../assets/images/main/Untitled design (14).png";
import Main3 from "../assets/images/main/image4.png";


const Main = () => {
  return (
    <div class=" bg-gradient-to-tr from-dark-1000 via-dark-1000 to-primary-900" 
    style={{
      backgroundImage: `url('${Main3}')`,
      backgroundSize: 'cover',
    }}
    >
      
      <section class="relative lg:min-h-[920px] pt-24 pb-10 sm:pt-32 sm:pb-16 lg:pb-24">
        {/* <div class="absolute  bottom-0  z-10 hidden lg:flex"> */}
          {/* <img class="hidden w-full lg:block" src="https://cdn.rareblocks.xyz/collection/celebration/images/hero/5/credit-cards.png" alt="" /> */}
          {/* <img class="block  w-[700px]  mb-28 ml-80" src={Main1} alt="" /> */}
          {/* <img class="block    " src={Main3} alt="" /> */}
        {/* </div> */}

        <div class="px-4 pt-24 mx-auto max-w-8xl sm:px-6 lg:px-8 mt-20 relative z-20 grid grid-cols-2 ">
          <div class="max-w-xl mx-auto text-center">
            <h1 class="text-4xl font-bold sm:text-6xl">
              <span class="text-white bg-clip-text bg-gradient-to-r from-green-300 to-white">
                {" "}
                Dijital Geleceğe Adım Atın, Başarıyı Yakalayın{" "}
              </span>
            </h1>
            <p class="mt-5 text-base text-white sm:text-xl">
              {" "}
              İşletmenizin büyümesine katkı sağlayacak yenilikçi web
              çözümleriyle geleceğe adım atın. İhtiyaçlarınızı analiz edip size
              özel çözümler sunuyoruz. Kullanıcı dostu arayüzler, güçlü altyapı
              ve sonuç odaklı projelerle başarıyı yakalayın.
            </p>

            
          </div>
          {/* <div className="w-[800px] block ml-10">
            <img className="w-[800px] block mb-12" src={Main2} alt="" />
          </div> */}
        </div>
        
      </section>
    </div>
  );
};

export default Main;
